import { Inject, Injectable } from '@angular/core';
import { filter, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { UsersComponentModel } from 'src/app/business/user/users.component.model';
import { IStateRegistry } from 'src/app/common/contracts/state/state-registry';
import { ISyncStatus } from 'src/app/common/contracts/sync/sync-status';
import { Deferred } from 'src/app/common/deferred/deferred';
import { UsersDB } from 'src/app/common/repository/databases';
import { StateRegistry } from 'src/app/common/state/state-registry';
import { SyncStatus } from 'src/app/common/sync/sync-status';

import { UsersModelName } from '../models/model-names';
import { AuthService } from './auth.service';
import { ConnectionStateService } from './connection-state.service';
import { IGenericStorage } from './contracts/database/generic-storage';
import { IConnectionStateService } from './contracts/sync/connection-state-service';
import { IFeathersAppProvider } from './contracts/sync/feathers-app-provider';
import { DatabaseService } from './database.service';
import { FeathersService } from './feathers.service';

@Injectable({ providedIn: 'root' })
export class OnlineStatusService {
  private _userDb: IGenericStorage;
  private _ready = new Deferred<void>();

  constructor(
    @Inject(FeathersService) private _feathersAppProvider: IFeathersAppProvider,
    @Inject(ConnectionStateService) private _connectionStateService: IConnectionStateService,
    private _authenticationService: AuthService,
    private _databaseService: DatabaseService,

    private _userComponentModel: UsersComponentModel,

    @Inject(StateRegistry) private _stateRegistry: IStateRegistry,
    @Inject(SyncStatus) private _syncStatus: ISyncStatus
  ) {
    this._syncStatus.completedSync$
      .pipe(takeWhile(completed => !completed))
      .subscribe({ complete: () => this._ready.resolve() });
  }

  private resetUserOnlineStatus() {
    return new Promise<void>(resolve => {
      this._userComponentModel
        .getAll()
        .pipe(
          take(1),
          map(users => users.map(user => ({ ...user, isOnline: false })))
        )
        .subscribe({
          next: async users => {
            try {
              for (const user of users) {
                await this._userDb.set(user._id, user);
                await this._stateRegistry.updateBySync(UsersModelName, 'items', [user]);
              }
            } catch (error) {
              window.logger.error('reset online status failed', error);
            }
          },
          complete: () => resolve(),
          error: err => {
            window.logger.error('fetching all users failed', err);
            resolve();
          },
        });
    });
  }

  public async init(): Promise<void> {
    this._userDb = await this._databaseService.getDatabase(UsersDB);
    await this._userDb.create();

    await this._ready.promise;

    await this.resetUserOnlineStatus();

    this._connectionStateService.connected
      .pipe(
        tap(isOnline => {
          if (!isOnline) {
            this.resetUserOnlineStatus();
          }
        }),
        filter(isOnline => isOnline),
        switchMap(() => this._authenticationService.authenticatedEventPublisher),
        filter(authEventData => authEventData.authOnline && authEventData.isAuthenticated)
      )
      .subscribe(async () => {
        this._feathersAppProvider.app.service('ping').removeAllListeners('removed');
        this._feathersAppProvider.app.service('ping').removeAllListeners('created');
        this._feathersAppProvider.app.service('ping').removeAllListeners('updated');

        this._feathersAppProvider.app.service('ping').on('created', item => this.setUserOnlineStatus(item));
        this._feathersAppProvider.app.service('ping').on('updated', item => this.setUserOnlineStatus(item));
        this._feathersAppProvider.app.service('ping').on('removed', item => this.setUserOnlineStatus(item));

        const pings = await this._feathersAppProvider.app
          .service('ping')
          .find({ query: { organization: this._authenticationService.authentication.account.organization._id } });

        for (const ping of pings) {
          await this.setUserOnlineStatus({ ...ping, isOnline: true });
        }
      });
  }

  private async setUserOnlineStatus(ping) {
    const pingId = (ping || { _id: '' })._id.split(':::');
    if (pingId.length !== 2) {
      return;
    }
    const user = await this._userDb.get(pingId[1]);
    if (!user || (user.isOnline && user.isOnline === ping.isOnline)) {
      return;
    }

    const updatedUser = { ...user, isOnline: ping.isOnline };
    await this._userDb.set(user._id, updatedUser);
    await this._stateRegistry.updateBySync(UsersModelName, 'items', [updatedUser]);
  }
}
