import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IGeneralEntityService } from 'src/app/shared/services/contracts/general-entity.service';
import { IProviderConfig, ProviderConfigToken } from 'src/app/shared/services/provider-config';
import { IListView } from '../../contracts/list-view';

@Component({
  selector: 'autocomplete-mobile-search-view',
  templateUrl: 'autocomplete-mobile-search-view.component.html',
  styleUrls: ['autocomplete-mobile-search-view.component.scss'],
})
export class AutocompleteMobileSearchViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public showAllOnStart: boolean;
  public placeholder = '';
  public busy = false;
  public hasAnySearchResults = false;
  public hasSearchedAtLeastOnce = false;
  public errorMessage: string;

  private _itemsProvider: IGeneralEntityService;
  private toSearchTerm: (query: string) => string;
  private filterItems: (results: any[]) => any[];
  private _type:
    | 'doctor'
    | 'hospital'
    | 'insuranceContract'
    | 'nursingHome'
    | 'nursingService'
    | 'payer'
    | 'postalCode'
    | 'group'
    | 'region'
    | 'productGroup'
    | 'pharmacy'
    | 'countryCode';
  private _itemFactory: ComponentFactory<IListView>;
  public searchValue = '';

  @ViewChild('pickContainer', { read: ViewContainerRef, static: true })
  private _pickContainer;
  @ViewChild('searchbar', { static: true })
  private _searchBar;
  private _subscriptions = [];

  constructor(
    private modalCtrl: ModalController,
    private _navParams: NavParams,
    @Inject(ProviderConfigToken) private _providerConfig: IProviderConfig,
    private _resolver: ComponentFactoryResolver // private _keyboard: Keyboard, TODO: Fix Keyboard
  ) {
    this.placeholder = this._navParams.get('placeholder');
    this._itemsProvider = this._navParams.get('itemsProvider');
    this.toSearchTerm = this._navParams.get('toSearchTerm');
    this.filterItems = this._navParams.get('filterItems');
    this._type = this._navParams.get('type');
    this.errorMessage = this._navParams.get('errorMessage');
    this._itemFactory = this._resolver.resolveComponentFactory(this._providerConfig[this._type].listItem);
  }

  async ngOnInit() {
    if (this.showAllOnStart) {
      this.clearSearch();
      await this.searchValues();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._searchBar.setFocus();
    }, 1000);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription: Subscription) => {
      if (subscription.closed) {
        return;
      }
      subscription.unsubscribe();
    });
  }

  async searchValues(): Promise<void> {
    this._pickContainer.clear();
    this.busy = true;
    this.hasSearchedAtLeastOnce = true;
    this.searchValue = this._searchBar.value;

    let items;
    // eslint-disable-next-line no-unused-expressions
    this.searchValue.length === 0
      ? (items = this.showAllOnStart ? this.filterItems(await this._itemsProvider.getAll()) : [])
      : (items = this.filterItems(await this._itemsProvider.query(this.toSearchTerm(this.searchValue))));
    this.hasAnySearchResults = items.length > 0;
    for (const item of items) {
      const listViewItem = this._pickContainer.createComponent(this._itemFactory);
      if (listViewItem) {
        listViewItem.instance.item = item;
        listViewItem.instance.selected = false;
        this._subscriptions.push(
          listViewItem.instance.clicked.pipe(take(1)).subscribe(value => {
            this._subscriptions.forEach(subscription => subscription.unsubscribe());
            this.modalCtrl.dismiss(value).catch(console.error);
          })
        );
      }
    }
    this.busy = false;
  }

  clearSearch() {
    this.searchValue = '';
    this._searchBar.value = '';
    this._pickContainer.clear();
  }

  public async closeView() {
    await this.modalCtrl.dismiss().catch(error => console.log(error));
  }
}
