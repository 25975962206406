import { IPlatformSync } from '../contracts/sync/platform-sync';
import { Deferred } from '../deferred/deferred';

export class PlatformSync implements IPlatformSync {
  private _isCapacitor: boolean;
  private _canBeSynced: boolean;
  private _ready = new Deferred<void>();

  public get canBeSynced(): boolean {
    return this._canBeSynced;
  }

  public set canBeSynced(canSync: boolean) {
    this._canBeSynced = canSync;
    this._ready.resolve();
  }

  public get isCapacitor(): boolean {
    return this._isCapacitor;
  }

  public set isCapacitor(capacitor: boolean) {
    this._isCapacitor = capacitor;
  }

  public get ready(): Promise<void> {
    return this._ready.promise;
  }
}

const PLATFORMSYNC: IPlatformSync = new PlatformSync();

export default PLATFORMSYNC;
