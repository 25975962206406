import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import {
  AppointmentDB,
  ArticleDB,
  AttachmentDB,
  AttributeTemplateDB,
  AuditDB,
  ConfigDB,
  ContractArticleGroupDB,
  DeviceDB,
  DoctorDB,
  ErpOrderDB,
  ErpTaskDB,
  FaxDB,
  GroupDB,
  HospitalDB,
  InstitutionAuditDB,
  InstitutionContactDB,
  InstitutionNoteDB,
  InsuranceContractDB,
  IntegratedCareDB,
  IntermediaryDB,
  LoggingDB,
  MaintenanceDB,
  MatchDB,
  NursingHomeDB,
  NursingServiceDB,
  PatientAppUserChannelDB,
  PatientAppUserDB,
  PatientDB,
  PatientNotesDB,
  PayerDB,
  PayerInfoDB,
  PharmacyDB,
  PostalCodeDB,
  ProductGroupDB,
  RegionDB,
  ReminderDB,
  ReportDB,
  RightsetDB,
  SingleOrderDB,
  StandardCareProposalDB,
  SubunitDB,
  SyncTimestampDB,
  TemplateDB,
  UsersDB,
} from 'src/app/common/repository/databases';
import { AlbertaStorage } from 'src/app/common/storage/alberta-storage';
import { SqliteStorageFactory } from 'src/app/common/storage/sqlite-storage-factory';
import { AuthService } from './auth.service';
import { IGenericStorage } from './contracts/database/generic-storage';
import { Branch, EnvironmentService } from './environment/environment.service';
import { Drivers } from '@ionic/storage';

const ALL_DRIVERS = [Drivers.IndexedDB, Drivers.LocalStorage];

@Injectable({ providedIn: 'root' })
export class DatabaseFactory {
  private _dbName = 'itlabs.db';
  private _storages: Record<string, IGenericStorage>;

  private _isMobile: boolean;

  get isMobile(): boolean {
    return this._isMobile;
  }

  get isFirstStart(): boolean {
    return !window.localStorage.getItem('isFirstStart');
  }

  set isFirstStart(isFirstStart: boolean) {
    window.localStorage.setItem('isFirstStart', String(isFirstStart));
  }

  constructor(
    public translate: TranslateService,
    private _authenticationService: AuthService,
    private _environmentService: EnvironmentService,
    @Inject(PlatformSyncToken) private _platformSync: IPlatformSync,
    private _sqliteStorageFactory: SqliteStorageFactory
  ) {}

  async createStorages(isMobile: boolean): Promise<Record<string, IGenericStorage>> {
    this._isMobile = isMobile;
    await this._platformSync.ready;
    await this.createDbName();
    if (this._isMobile) {
      await this.createMobileStorages();
    } else {
      this.createBrowserStorages();
    }
    if (!this._platformSync.isCapacitor) {
      await this.createLocalStorages();
    }
    await this.resetNotifications();
    return this._storages;
  }

  private async resetNotifications() {
    const value = window.localStorage.getItem('notifications');
    if (value && value === 'finished') {
      return;
    }
    if (!this._platformSync.isCapacitor) {
      const stateStorage: IGenericStorage = this._storages['statePersister.db'];
      await stateStorage.create();
      await stateStorage.clear();
    }
    const attachmentStorage: IGenericStorage = this._storages[AttachmentDB];
    await attachmentStorage.create();
    await attachmentStorage.clear();
    const syncTimestampStorage: IGenericStorage = this._storages['syncTimestamp.db'];
    await syncTimestampStorage.create();
    await syncTimestampStorage.removeItem(AttachmentDB.substring(0, AttachmentDB.length - 3));
    window.localStorage.setItem('notifications', 'finished');
  }

  private createDbName() {
    return new Promise<void>(resolve => {
      this._authenticationService.authenticatedEventPublisher
        .pipe(takeWhile(authEventData => !authEventData.isAuthenticated))
        .subscribe({
          complete: async () => {
            if (!this._authenticationService.authentication || !this._authenticationService.authentication.account) {
              throw new Error('No logged in user instance found.');
            }
            await this._environmentService.ready;
            const account = this._authenticationService.authentication.account;
            const branch =
              this._environmentService.branch === Branch.production ? 'master' : this._environmentService.branch;
            this._dbName = `${account.firstName[0]}${account.lastName[0]}${account._id.slice(-4)}_${branch}`;

            await this._sqliteStorageFactory.configure(this._dbName);

            resolve();
          },
        });
    });
  }

  private isDeletable(deletable: boolean, browserDeletable: boolean = true) {
    return this._platformSync.isCapacitor ? deletable : browserDeletable;
  }

  private async createMobileStorages() {
    this._storages = {
      [MaintenanceDB]: await this._sqliteStorageFactory.create(MaintenanceDB),
      'logger.db': await this._sqliteStorageFactory.create('logger.db', false, false),
      [AttachmentDB]: await this._sqliteStorageFactory.create(AttachmentDB),
      [PatientDB]: await this._sqliteStorageFactory.create(PatientDB),
      [ArticleDB]: await this._sqliteStorageFactory.create(ArticleDB),
      [AuditDB]: await this._sqliteStorageFactory.create(AuditDB),
      [TemplateDB]: await this._sqliteStorageFactory.create(TemplateDB),
      [MatchDB]: await this._sqliteStorageFactory.create(MatchDB),
      [ReportDB]: await this._sqliteStorageFactory.create(ReportDB),
      [PatientNotesDB]: await this._sqliteStorageFactory.create(PatientNotesDB),
      [NursingHomeDB]: await this._sqliteStorageFactory.create(NursingHomeDB),
      [NursingServiceDB]: await this._sqliteStorageFactory.create(NursingServiceDB),
      [HospitalDB]: await this._sqliteStorageFactory.create(HospitalDB),
      [InstitutionContactDB]: await this._sqliteStorageFactory.create(InstitutionContactDB),
      [InstitutionNoteDB]: await this._sqliteStorageFactory.create(InstitutionNoteDB),
      [InstitutionAuditDB]: await this._sqliteStorageFactory.create(InstitutionAuditDB),
      [PostalCodeDB]: await this._sqliteStorageFactory.create(PostalCodeDB),
      [DoctorDB]: await this._sqliteStorageFactory.create(DoctorDB),
      [PayerDB]: await this._sqliteStorageFactory.create(PayerDB),
      [PayerInfoDB]: await this._sqliteStorageFactory.create(PayerInfoDB),
      [UsersDB]: await this._sqliteStorageFactory.create(UsersDB),
      [GroupDB]: await this._sqliteStorageFactory.create(GroupDB),
      [RightsetDB]: await this._sqliteStorageFactory.create(RightsetDB),
      [RegionDB]: await this._sqliteStorageFactory.create(RegionDB),
      [StandardCareProposalDB]: await this._sqliteStorageFactory.create(StandardCareProposalDB),
      [ProductGroupDB]: await this._sqliteStorageFactory.create(ProductGroupDB),
      [InsuranceContractDB]: await this._sqliteStorageFactory.create(InsuranceContractDB),
      [PharmacyDB]: await this._sqliteStorageFactory.create(PharmacyDB),
      [FaxDB]: await this._sqliteStorageFactory.create(FaxDB),
      [SyncTimestampDB]: await this._sqliteStorageFactory.create(SyncTimestampDB, false, false),
      [ErpOrderDB]: await this._sqliteStorageFactory.create(ErpOrderDB),
      [DeviceDB]: await this._sqliteStorageFactory.create(DeviceDB),
      [AttributeTemplateDB]: await this._sqliteStorageFactory.create(AttributeTemplateDB),
      [SubunitDB]: await this._sqliteStorageFactory.create(SubunitDB),
      [ConfigDB]: await this._sqliteStorageFactory.create(ConfigDB),
      [AppointmentDB]: await this._sqliteStorageFactory.create(AppointmentDB),
      [ErpTaskDB]: await this._sqliteStorageFactory.create(ErpTaskDB),
      [PatientAppUserDB]: await this._sqliteStorageFactory.create(PatientAppUserDB),
      [PatientAppUserChannelDB]: await this._sqliteStorageFactory.create(PatientAppUserChannelDB),
      [IntegratedCareDB]: await this._sqliteStorageFactory.create(IntegratedCareDB),
      [SingleOrderDB]: await this._sqliteStorageFactory.create(SingleOrderDB),
      [ContractArticleGroupDB]: await this._sqliteStorageFactory.create(ContractArticleGroupDB),
      [IntermediaryDB]: await this._sqliteStorageFactory.create(IntermediaryDB),
      [ReminderDB]: await this._sqliteStorageFactory.create(ReminderDB),
      ['commandQueue.db']: await this._sqliteStorageFactory.create('commandQueue.db', false, false),
      ['attachmentsToSave']: await this._sqliteStorageFactory.create('attachmentsToSave', false, false),
      ['attachmentsToUpdate']: await this._sqliteStorageFactory.create('attachmentsToUpdate', false, false),
      ['attachmentsToRemove']: await this._sqliteStorageFactory.create('attachmentsToRemove', false, false),
      ['attachmentChunksMeta']: await this._sqliteStorageFactory.create(
        'attachmentChunksMeta',
        this.isDeletable(true, false),
        false
      ),
      ['attachmentChunks']: await this._sqliteStorageFactory.create(
        'attachmentChunks',
        this.isDeletable(true, false),
        false
      ),
    };
  }

  private async createLocalStorages() {
    const local = new AlbertaStorage(
      {
        name: `${this._dbName}_statePersister.db`,
        storeName: 'statePersister.db',
        driverOrder: ALL_DRIVERS,
      },
      false,
      false
    );
    await local.create();
    this._storages['statePersister.db'] = local;
  }

  async createBrowserStorages(): Promise<void> {
    this._storages = {
      [AttachmentDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${AttachmentDB}`,
          storeName: AttachmentDB,
          driverOrder: ALL_DRIVERS,
        },
        false
      ),
      [PatientDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PatientDB}`,
          storeName: PatientDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [ArticleDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ArticleDB}`,
          storeName: ArticleDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [AuditDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${AuditDB}`,
          storeName: AuditDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [TemplateDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${TemplateDB}`,
          storeName: TemplateDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [MatchDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${MatchDB}`,
          storeName: MatchDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [ReportDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ReportDB}`,
          storeName: ReportDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [PatientNotesDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PatientNotesDB}`,
          storeName: PatientNotesDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [NursingHomeDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${NursingHomeDB}`,
          storeName: NursingHomeDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [NursingServiceDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${NursingServiceDB}`,
          storeName: NursingServiceDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [HospitalDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${HospitalDB}`,
          storeName: HospitalDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [InstitutionContactDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${InstitutionContactDB}`,
          storeName: InstitutionContactDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [InstitutionNoteDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${InstitutionNoteDB}`,
          storeName: InstitutionNoteDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [InstitutionAuditDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${InstitutionAuditDB}`,
          storeName: InstitutionAuditDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [PostalCodeDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PostalCodeDB}`,
          storeName: PostalCodeDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [DoctorDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${DoctorDB}`,
          storeName: DoctorDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [SubunitDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${SubunitDB}`,
          storeName: SubunitDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [PayerDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PayerDB}`,
          storeName: PayerDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [PayerInfoDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PayerInfoDB}`,
          storeName: PayerInfoDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [UsersDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${UsersDB}`,
          storeName: UsersDB,
          driverOrder: ALL_DRIVERS,
        },
        this._platformSync.canBeSynced
      ),
      [GroupDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${GroupDB}`,
          storeName: GroupDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [RightsetDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${RightsetDB}`,
          storeName: RightsetDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [RegionDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${RegionDB}`,
          storeName: RegionDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [StandardCareProposalDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${StandardCareProposalDB}`,
          storeName: StandardCareProposalDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [ProductGroupDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ProductGroupDB}`,
          storeName: ProductGroupDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [InsuranceContractDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${InsuranceContractDB}`,
          storeName: InsuranceContractDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [PharmacyDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PharmacyDB}`,
          storeName: PharmacyDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [FaxDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${FaxDB}`,
          storeName: FaxDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [SyncTimestampDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${SyncTimestampDB}`,
          storeName: SyncTimestampDB,
          driverOrder: ALL_DRIVERS,
        },
        false
      ),
      [ErpOrderDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ErpOrderDB}`,
          storeName: ErpOrderDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),

      [DeviceDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${DeviceDB}`,
          storeName: DeviceDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [AttributeTemplateDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${AttributeTemplateDB}`,
          storeName: AttributeTemplateDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [MaintenanceDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${MaintenanceDB}`,
          storeName: MaintenanceDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [ConfigDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ConfigDB}`,
          storeName: ConfigDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [AppointmentDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${AppointmentDB}`,
          storeName: AppointmentDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [ErpTaskDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ErpTaskDB}`,
          storeName: ErpTaskDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [PatientAppUserDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PatientAppUserDB}`,
          storeName: PatientAppUserDB,
          driverOrder: ALL_DRIVERS,
        },
        false
      ),
      [PatientAppUserChannelDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PatientAppUserChannelDB}`,
          storeName: PatientAppUserChannelDB,
          driverOrder: ALL_DRIVERS,
        },
        false
      ),
      [IntegratedCareDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${IntegratedCareDB}`,
          storeName: IntegratedCareDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),

      [SingleOrderDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${SingleOrderDB}`,
          storeName: SingleOrderDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [ContractArticleGroupDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ContractArticleGroupDB}`,
          storeName: ContractArticleGroupDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
      [IntermediaryDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${IntermediaryDB}`,
          storeName: IntermediaryDB,
        },
        true
      ),
      [ReminderDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ReminderDB}`,
          storeName: ReminderDB,
          driverOrder: ALL_DRIVERS,
        },
        true
      ),
    };

    this._storages['logger.db'] = new AlbertaStorage(
      {
        name: `${this._dbName}_${LoggingDB}`,
        storeName: 'logger',
        driverOrder: ALL_DRIVERS,
      },
      true
    );

    this._storages['attachmentChunks'] = new AlbertaStorage(
      {
        name: `${this._dbName}_attachmentChunks`,
        storeName: 'attachmentChunks',
        driverOrder: ALL_DRIVERS,
      },
      this.isDeletable(true, false),
      false
    );

    this._storages['attachmentsToSave'] = new AlbertaStorage(
      {
        name: `${this._dbName}_attachmentsToSave`,
        storeName: 'attachmentsToSave',
        driverOrder: ALL_DRIVERS,
      },
      false,
      false
    );

    this._storages['attachmentsToUpdate'] = new AlbertaStorage(
      {
        name: `${this._dbName}_attachmentsToUpdate`,
        storeName: 'attachmentsToUpdate',
        driverOrder: ALL_DRIVERS,
      },
      false,
      false
    );

    this._storages['attachmentsToRemove'] = new AlbertaStorage(
      {
        name: `${this._dbName}_attachmentsToRemove`,
        storeName: 'attachmentsToRemove',
        driverOrder: ALL_DRIVERS,
      },
      false,
      false
    );

    this._storages['commandQueue.db'] = new AlbertaStorage(
      {
        name: `${this._dbName}_commandQueue.db`,
        storeName: 'commandQueue.db',
        driverOrder: ALL_DRIVERS,
      },
      false,
      false
    );

    for (const key in this._storages) {
      if (Object.prototype.hasOwnProperty.call(this._storages, key)) {
        await this._storages[key as keyof Record<string, IGenericStorage>].create();
      }
    }
  }
}
