interface Motto {
  readonly quoteText: string;
  readonly author: string;
  readonly date?: string;
}

const fallBackMotto: Motto = {
  quoteText: 'Tue es, oder tue es nicht. Es gibt kein Versuchen.',
  author: 'Yoda',
};

export function getMotto(thisDay: string): Motto {
  const foundSpecialMotto = SPECIALMOTTOS.find(element => element.date === thisDay);
  if (foundSpecialMotto) {
    return foundSpecialMotto;
  }
  const foundNormalMotto = MOTTOS[Math.floor(Math.random() * MOTTOS.length)];
  if (foundNormalMotto) {
    return foundNormalMotto;
  }
  return fallBackMotto;
}

const MOTTOS: Array<Motto> = [
  {
    quoteText: 'Oberflächlichkeit fördert die Spontanität. Tiefgründigkeit fördert die gesamte Entwicklung.',
    author: 'Sylvia Amstadt',
  },
  {
    quoteText: 'Leistung ist das Ergebnis von Motivation.',
    author: 'Martin Fietzek',
  },
  {
    quoteText: 'Alleine auf einer Parkbank zu sitzen ist manchmal auch Inspiration.',
    author: 'Klaus',
  },
  {
    quoteText: 'Erfolg hat immer mit Teamwork zu tun.',
    author: 'Hannes Feiersinger',
  },
  {
    quoteText: 'Ohne meine Stimmungsschwankungen gäbe es ja gar keine Stimmung.',
    author: 'Sylvia Amstadt',
  },
  {
    quoteText: 'Komplizierte Dinge brauchen Konzentration, schwierige Dinge brauchen Motivation.',
    author: 'Kühn-Görg Monika',
  },
  {
    quoteText: 'Der Geist braucht Inspiration, um zu inspirieren.',
    author: 'Klaus Seibold',
  },
  {
    quoteText: '10 Prozent Strategie, 90 Prozent Kommunikation ergeben 100 Prozent Team - Erfolg!',
    author: 'Andre Dieckschulte',
  },
  {
    quoteText: 'Auch eine Reise von tausend Meilen beginnt mit einem Schritt.',
    author: 'Laotse',
  },
  {
    quoteText: 'Man kann viel, wenn man sich nur recht viel zutraut.',
    author: 'Alexander von Humboldt',
  },
  {
    quoteText: 'Wenn du auf eine Wand stößt, tritt sie ein.',
    author: 'Patti Smith',
  },
  {
    quoteText: 'Ein tiefer Fall führt oft zu höherem Glück.',
    author: 'William Shakespeare',
  },
  {
    quoteText: 'Über sich selbst zu lachen, heißt sich selbst zu lieben.',
    author: 'Mickey Mouse',
  },
  {
    quoteText: 'Gestern hast du morgen gesagt.',
    author: 'Unbekannt',
  },
  {
    quoteText: 'Erfolg ist die Belohnung für schwere Arbeit.',
    author: 'Sophokles',
  },
  {
    quoteText: 'Einige erzeugen Freude, wohin immer sie gehen. Andere, wann immer sie gehen.',
    author: 'Oscar Wilde',
  },
  {
    quoteText: 'Eine runde Persönlichkeit aufzubauen, hat nicht mit Essen zu tun.',
    author: 'M.B. Hermann',
  },
  {
    quoteText: 'Problemzonen beginnen im Kopf.',
    author: 'Beat Jan',
  },
  {
    quoteText: 'Man kann viel, wenn man sich nur recht viel zutraut.',
    author: 'Alexander von Humboldt',
  },
  {
    quoteText: 'Lernen ist Erfahrung. Alles andere ist nur Information.',
    author: 'Albert Einstein',
  },
  {
    quoteText: 'Niemand tut etwas ohne Motivation.',
    author: 'Volker Harmgardt',
  },
  {
    quoteText: 'Mit Frauen soll man sich nie unterstehen zu scherzen.',
    author: 'Johann Wolfgang von Goethe',
  },
  {
    quoteText: 'Der Vogel, der früh aufsteht, frisst den Wurm. Der Wurm, der früh aufsteht, wird vom Vogel gefressen.',
    author: 'Milena A.L',
  },
  {
    quoteText: 'Inspiration ist die Basis der Kunst - Reflexion die Entwicklung.',
    author: 'Chinesische Weisheit',
  },
  {
    quoteText: 'Gestern hast du morgen gesagt.',
    author: 'Unbekannt',
  },
  {
    quoteText: 'Wer Freude bei der Arbeit hat, ist imstande, viel zu leisten.',
    author: 'Marion Hedda Ilse Gräfin Dönhoff',
  },
  {
    quoteText: 'Im Grunde sind es doch Verbindungen mit Menschen, die dem Leben seinen Wert geben.',
    author: 'Wilhelm von Humboldt',
  },
  {
    quoteText: 'Ändere deine Gedanken und du veränderst deine Welt.',
    author: 'Norman Vincent Peale',
  },
  {
    quoteText: 'Ein reicher Mensch ist einer, der weiß dass er genug hat.',
    author: 'Laotse',
  },
  {
    quoteText: 'Wem genug zu wenig ist, dem ist nichts genug.',
    author: 'Unbekannt',
  },
  {
    quoteText: 'Probleme kann man niemals mit derselben Denkweise lösen, durch die sie entstanden sind.',
    author: 'Albert Einstein',
  },
  {
    quoteText: 'Egal ob du rennst oder gehst. Der Weg bleibt der selbe.',
    author: 'Japanische Weisheit',
  },
  {
    quoteText: 'Stop starting. Start finishing.',
    author: 'Agile',
  },
  {
    quoteText: 'Zwischen zu früh und zu spät liegt immer nur ein Augenblick',
    author: 'Franz Werfel',
  },
  {
    quoteText: 'Wo Anmaßung mir wohl gefällt? An Kindern. Denen gehört die Welt.',
    author: 'Johann Wolfgang von Goethe',
  },
  {
    quoteText: 'Die größte Offenbarung ist die Stille.',
    author: 'Laotse',
  },
  {
    quoteText: 'Es gibt Augenblicke, da gelingt uns alles. Kein Grund zu erschrecken: Das geht vorüber.',
    author: 'Jules Renard',
  },
  {
    quoteText: 'Glück ist das einzige, was wir anderen geben können, ohne es selbst zu haben.',
    author: 'Carmen Sylva',
  },
  {
    quoteText: 'Wir suchen die Wahrheit. Finden wollen wir sie aber nur dort, wo es uns beliebt.',
    author: 'Marie von Ebner-Eschenbach',
  },
  {
    quoteText: 'Immer wenn wir lachen, stirbt irgendwo ein Problem.',
    author: 'Unbekannt',
  },
  {
    quoteText: 'Wer viel Geld hat, ist reich. Wer keine Krankheit hat, ist glücklich!',
    author: 'Chinesische Weisheit',
  },
  {
    quoteText: 'Das Auge macht das Bild, nicht die Kamera.',
    author: 'Gisèle Freund',
  },
  {
    quoteText: 'Müde macht uns die Arbeit die wir liegen lassen, nicht die wir tun.',
    author: 'Marie von Ebner- Eschenbach',
  },
  {
    quoteText: 'Wo Freundlichkeit herrscht, gibt es Güte, und wo es Güte gibt, da ist auch Magie.',
    author: 'Aus Disneys Cinderella',
  },
  {
    quoteText: 'Wer einen Fehler gemacht hat und ihn nicht korrigiert, begeht einen Zweiten.',
    author: 'Konfuzius',
  },
  {
    quoteText: 'Misserfolg ist lediglich eine Gelegenheit, mit neuen Ansichten noch einmal anzufangen.',
    author: 'Henry Ford',
  },
  {
    quoteText: 'Oberflächlichkeit fördert die Spontanität. Tiefgründigkeit fördert die gesamte Entwicklung',
    author: 'Sylvia Amstadt',
  },
  {
    quoteText: 'Erfolg steigt nur zu Kopf, wenn dort der erforderliche Hohlraum vorhanden ist.',
    author: 'Unbekannt',
  },
  {
    quoteText: 'Lernen ist wie Rudern gegen den Strom. Hört man damit auf, treibt man zurück.',
    author: 'Laozi',
  },
  {
    quoteText:
      'Der Vorteil der Klugheit liegt darin, dass man sich dumm stellen kann. Das Gegenteil ist schon schwieriger.',
    author: 'Kurt Tucholksky',
  },
  {
    quoteText: 'Ich denke niemals an die Zukunft. Sie kommt früh genau.',
    author: 'Albert Einstein',
  },
  {
    quoteText: 'Es ist nicht zu wenig Zeit, die wir haben, sondern es ist zu viel Zeit, die wir nicht nutzen.',
    author: 'Lucius Annaeus Seneca',
  },
  {
    quoteText: 'Erfolg hat immer mit Teamwork zu tun.',
    author: 'Hannes Feiersinger',
  },
  {
    quoteText: 'Humor ist der Knopf, der verhindert, dass uns der Kragen platzt.',
    author: 'Hermann Hesse',
  },
  {
    quoteText: 'Eine gute Schwäche ist besser als eine schlechte Stärke.',
    author: 'Charles Aznavour',
  },
  {
    quoteText: 'Ohne meine Stimmungsschwankungen gäbe es ja gar keine Stimmung.',
    author: 'Sylvia Amstadt',
  },
  {
    quoteText: 'Man muß die Tatsachen kennen, bevor man sie verdrehen kann.',
    author: 'Mark Twain',
  },
  {
    quoteText: 'Das Gewissen ist eine Schwiegermutter, deren Besuch nie endet.',
    author: 'Henry Louis Mencken',
  },
  {
    quoteText: 'Keine Schuld ist dringender, als die, Danke zu sagen.',
    author: 'Marcus Tullius Cicero',
  },
  {
    quoteText: 'Leben ist das was passiert, während du beschäftigt bist, andere Pläne zu machen.',
    author: 'John Lennon',
  },
  {
    quoteText: 'Man sieht nur mit dem Herzen gut. Das Wesentliche ist für die Augen unsichtbar',
    author: 'Der kleine Prinz',
  },
  {
    quoteText: 'Schließe deine Augen und sieh.',
    author: 'James Joyce',
  },
  {
    quoteText: 'Kein Geist ist in Ordnung, dem der Sinn für Humor fehlt.',
    author: 'Samuel Coleridge',
  },
  {
    quoteText: 'Die Musik wird treffend als Sprache der Engel beschrieben.',
    author: 'Thomas Carlyle',
  },
  {
    quoteText: 'Die Zeit ist schlecht? Du bist da, sie besser zu machen.',
    author: 'Unbekannt',
  },
  {
    quoteText: 'Es gibt Augenblicke, da gelingt uns alles. Kein Grund zu erschrecken: Das geht vorüber.',
    author: 'Jules Renard',
  },
  {
    quoteText: 'Man ist meistens nur durch Nachdenken unglücklich.',
    author: 'Joseph Joubert',
  },
  {
    quoteText: 'Ihr aber seht und sagt: Warum? Aber ich träume und sage: Warum nicht?',
    author: 'Georg Bernard Shaw',
  },
  {
    quoteText: 'Es gibt keinen Weg zum Frieden, denn Frieden ist der Weg.',
    author: 'Mahatma Gandhi',
  },
  {
    quoteText: 'Wir sind für nichts so dankbar wie für Dankbarkeit.',
    author: 'Marie von Ebner- Eschenbach',
  },
  {
    quoteText: 'Lass die Dinge los, die du nicht ändern kannst und konzentriere dich auf das, was du kannst.',
    author: 'Aus Disneys Ratatouille',
  },
  {
    quoteText: 'Der Humor ist der Regenschirm des Weisen.',
    author: 'Erich Kästner',
  },
  {
    quoteText: 'Es gibt tausend Krankheiten, aber nur eine Gesundheit.',
    author: 'Ludwig Börne',
  },
  {
    quoteText: "Der Mensch is guad, de Leit' san schlecht!",
    author: 'Karl Valentin',
  },
  {
    quoteText: 'Welche eine himmlische Empfindung ist es, seinem Herzen zu folgen.',
    author: 'Johann Wolfgang von Goethe',
  },
  {
    quoteText: 'Morgen werde ich das sein, was ich heute baue. Heute bin ich das, was ich gestern gebaut habe.',
    author: 'James Joyce',
  },
  {
    quoteText: 'Nur wer sein Alter verleugnet, fühlt sich wirklich alt.',
    author: 'Lilli Palmer',
  },
  {
    quoteText: 'Wir müssen der Wandel sein, den wir in der Welt zu sehen wünschen',
    author: 'Mahatma Gandhi',
  },
  {
    quoteText: 'Erkennst du deine eigene Stärke, erkennst du auch die Optionen, die sich dir bieten.',
    author: 'Monika Kühn-Görg',
  },
  {
    quoteText: 'Nicht ärgern, nur wundern.',
    author: 'Deutsches Sprichwort',
  },
  {
    quoteText: 'Tierschutz ist Erziehung zur Menschlichkeit.',
    author: 'Albert Schweitzer',
  },
  {
    quoteText: 'Hakuna Matata. Das heißt: Keine Sorgen.',
    author: 'aus Disneys König der Löwen',
  },
  {
    quoteText: 'Nur die Zeit hat ewig Zeit.',
    author: 'Georg-Wilhelm Exler',
  },
  {
    quoteText: 'Wer viele Sprachen spricht, kann in vielen Sprachen Unsinn reden.',
    author: 'Roda Alexander',
  },
  {
    quoteText: 'Der einzige Ort, an dem der Erfolg vor der Arbeit kommt, ist im Wörterbuch.',
    author: 'Vidal Sassoon',
  },
  {
    quoteText: 'Wer will, dass die Welt so bleibt wie sie ist, der will nicht, dass sie bleibt.',
    author: 'Erich Fried',
  },
  {
    quoteText: 'Warum sollte man der Marine beitreten, wenn man ein Pirat sein kann?',
    author: 'Steve Jobs',
  },
  {
    quoteText: 'Im Morgen ist alles anders als im Jetzt und im Gestern.',
    author: 'Nicole Engelhardt',
  },
  {
    quoteText: 'Im Kampf zwischen dir und der Welt, stelle dich immer auf die Seite der Welt.',
    author: 'Franz Kafka',
  },
  {
    quoteText: 'Was die Welt vorwärtstreibt, ist nicht die Liebe, sondern der Zweifel.',
    author: 'Ustinov Peter',
  },
  {
    quoteText: 'Für kein Tier auf der Welt wird soviel gearbeitet wie für die Katz.',
    author: 'Unbekannt',
  },
  {
    quoteText: 'Zeit hat, wer sich Zeit nimmt.',
    author: 'Georg-Wilhelm Exler',
  },
  {
    quoteText: 'Entweder musst du deine Wünsche modifizieren oder deine Fähigkeiten verstärken.',
    author: 'Emanuel James',
  },
  {
    quoteText:
      'Es ist nicht deine Schuld, dass die Welt ist, wie sie ist. Es wäre nur deine Schuld, wenn sie so bleibt.',
    author: 'Die Ärzte',
  },
  {
    quoteText: 'Ein hübsches Kompliment ist eine glaubwürdige Übertreibung.',
    author: 'Alexander Peter',
  },
  {
    quoteText: 'Der Narr hält sich für weise, aber der Weise weiß, dass er ein Narr ist.',
    author: 'William Shakespeare',
  },
  {
    quoteText: 'Sage nicht "Ich kann es nicht". Es muss heißen "Ich kann es noch nicht".',
    author: 'Unbekannt',
  },
  {
    quoteText: 'Das Komplexe ist einfach, das Einfache ist komplex.',
    author: 'Angus Young',
  },
  {
    quoteText: 'Die größten Menschen sind jene, die anderen Hoffnung geben können.',
    author: 'Ernst Ferstl',
  },
  {
    quoteText: 'Es sind Fehler, die einen wirklich wachsen lassen. Du musst schlecht werden, um gut zu werden.',
    author: 'Paula Scher',
  },
  {
    quoteText: 'Solange Menschen denken, dass Tiere nicht fühlen, müssen Tiere fühlen, dass Menschen nicht denken.',
    author: 'Unbekannt',
  },
  {
    quoteText: 'Den Duft von Sommerregen nennt man “Petrichor”, von “petros” (Stein) und “ichor” (Blut der Götter).',
    author: 'Die Zeit Wissen',
  },
  {
    quoteText: 'Jeder fällt mal hin, aber erst, wenn man wieder aufsteht, lernt man zu laufen.',
    author: 'aus Disneys Bambi',
  },
  {
    quoteText: 'Ein Mensch lernt wenig von seinem Siege, aber viel von der Niederlage.',
    author: 'Unbekannt',
  },
  {
    quoteText: 'Zu niemanden ist man ehrlicher als zum Suchfeld von Google.',
    author: 'Constanze Kurz',
  },
  {
    quoteText: 'Ein Fremdwort für “Fremdwort” ist auch “Xenismus".',
    author: 'Die Zeit Wissen',
  },
  {
    quoteText: 'Warte nicht. Es gibt nie den passenden Moment.',
    author: 'Napoleon',
  },
  {
    quoteText: 'Der Blick in die Zukunft ist nur in der Gegenwart möglich.',
    author: 'Georg-Wilhelm Exler',
  },
  {
    quoteText: 'Wer dir überlegen, den beneide nicht. Wer dir unterlegen den verachte nicht.',
    author: 'Japanische Weisheit',
  },
  {
    quoteText: 'Geduld hat was mit Zeit zu tun. Manchmal auch mit viel Zeit.',
    author: 'Klaus Seibold',
  },
  {
    quoteText: 'Dankbarkeit ist das blühen des Glücks.',
    author: 'Unbekannt',
  },
  {
    quoteText: 'Erfolg ist, der eigenen Klarheit zu folgen.',
    author: 'Christoph Reiter',
  },
  {
    quoteText: 'Es gibt tausend Krankheiten, aber nur eine Gesundheit.',
    author: 'Carl Ludwig Börne',
  },
  {
    quoteText: 'Gib jedem Tag die Chance, der schönste deines Lebens zu werden.',
    author: 'Mark Twain',
  },
  {
    quoteText: 'Heilung ist eine Frage der Zeit. Manchmal ist es aber auch eine Frage der günstigen Gelegenheit.',
    author: 'Hippokrates',
  },
  {
    quoteText: 'Nichts ist wertvoller, als ein Mensch der ehrlich zu dir ist.',
    author: 'Martin Pioch',
  },
  {
    quoteText: 'Wege entstehen dadurch, dass man sie geht.',
    author: 'Franz Kafka',
  },
  {
    quoteText: 'Glück ist kein Zufall.',
    author: 'Georg-Wilhelm Exler',
  },
  {
    quoteText: 'Der Schwache kann nicht verzeihen. Verzeihen ist eine Eigenschaft des Starken.',
    author: 'Mahatma Gandhi',
  },
  {
    quoteText: 'Man liebt, weil man liebt. Dafür gibt es keinen Grund.',
    author: 'Paulo Coehlo',
  },
  {
    quoteText: 'Es ist der Geist, der sich den Körper baut.',
    author: 'Friedrich Schiller',
  },
  {
    quoteText: 'Manchmal sollte man sich lieber Nudeln statt Sorgen machen.',
    author: 'Unbekannt',
  },
  {
    quoteText: 'Geradeaus kann man nicht sehr weit kommen.',
    author: 'Der kleine Prinz',
  },
  {
    quoteText: 'Freundlichkeit ist eine Sprache, die Taube hören und Blinde lesen können',
    author: 'Mark Twain',
  },
  {
    quoteText: 'Gesundheit ist nicht alles, aber ohne Gesundheit ist alles nichts.',
    author: 'Arthur Schopenhauer',
  },
  {
    quoteText:
      'Erfolg, Wohlstand, Gesundheit und bereichernde Beziehungen sind Folgen des Glücks und nicht die Ursache.',
    author: 'Deepak Chopra',
  },
  {
    quoteText: 'Zufrieden sein heißt glücklich sein.',
    author: 'Martin Pioch',
  },
  {
    quoteText: 'Vergebung ändert nie deine Vergangenheit, aber bereichert deine Zukunft.',
    author: 'Japanische Weisheit',
  },
  {
    quoteText: 'Es gibt nur eine Krankheit, wie es nur eine Gesundheit gibt.',
    author: 'Ernst Jünger',
  },
  {
    quoteText: 'Über sich selbst zu lachen, heißt sich selbst zu lieben.“',
    author: 'aus Disneys Mickey Mouse',
  },
  {
    quoteText: 'Wer beim Säen träge wird, wird beim Ernten neidisch.',
    author: 'Japanische Weisheit',
  },
  {
    quoteText: 'Wer sich sorgt, ist selten glücklich.',
    author: 'Grabher Markus',
  },
  {
    quoteText: 'Der, der das Schiff erfunden hat, hat auch den Schiffbruch erfunden.',
    author: 'Seth Godin',
  },
  {
    quoteText: 'Umgib dich mit Menschen, die sich nach Sonnenschein anfühlen.',
    author: 'Unbekannt',
  },
  {
    quoteText:
      'Ein jeder hat seine eigne Art, glücklich zu sein, und niemand darf verlangen, dass man es in der seinigen sein soll.',
    author: 'Heinrich von Kleist',
  },
  {
    quoteText: 'Gesundheit bekommt man nicht im Handel, sondern durch den Lebenswandel.',
    author: 'Sebastian Kneipp',
  },
  {
    quoteText: 'Die meisten Menschen leben in den Ruinen ihrer Gewohnheiten.',
    author: 'Jean Cocteau',
  },
  {
    quoteText: 'Der Sieger hat viele Freunde, der Besiegte hat gute Freunde.',
    author: 'Unbekannt',
  },
  {
    quoteText:
      'Leben allein genügt nicht, sagte der Schmetterling. Sonnenschein, Freiheit und eine kleine Blume muss man auch haben.',
    author: 'Hand Christian Andersen',
  },
  {
    quoteText: 'Es gibt wohl nur wenig Tiere, die uns für Menschen halten.',
    author: 'Alfred Edmund Brehm',
  },
  {
    quoteText: 'Wer nicht weiß, wohin er will, der darf sich nicht wundern, wenn er ganz woanders ankommt.',
    author: 'Mark Twain',
  },
  {
    quoteText: 'Das Geheimnis des Erfolgs ist: es zu suchen!',
    author: 'Unbekannt',
  },
  {
    quoteText: 'Wichtig ist nicht besser zu sein als alle anderen. Wichtig ist, besser zu sein als du gestern warst.',
    author: 'Japanische Weisheit',
  },
  {
    quoteText: 'Freundschaft, das ist eine Seele in zwei Körpern.',
    author: 'Josi',
  },
  {
    quoteText: 'Wer immer das tut was er schon kann, der bleibt immer das was er schon ist.',
    author: 'Katrin Fröstl',
  },
  {
    quoteText: 'Freiheit und Leben kann man uns nehmen, die Ehre nicht.',
    author: 'Otto Wels',
  },
  {
    quoteText: 'Der Wechsel allein ist das Beständige.',
    author: 'Arthur Schopenhauer',
  },
  {
    quoteText: 'Keiner ist so blind, wie der der nicht sehen will.',
    author: 'Japanische Weisheit',
  },
  {
    quoteText: 'Die wahre Freude ist die Freude am anderen.',
    author: 'Unbekannt',
  },
  {
    quoteText: 'Mit einem Freund durch die Dunkelheit zu gehen ist besser als allein im Licht zu gehen.',
    author: 'Helen Keller',
  },
  {
    quoteText: 'Man entdeckt keine neuen Länder ohne das Ufer für eine lange Zeit aus den Augen zu verlieren.',
    author: 'André Gide',
  },
  {
    quoteText: 'Verlasse heute deine Komfortzone. Reflektiere am abend deinen Tag. Was ist passiert?',
    author: 'Achtsamkeit',
  },
  {
    quoteText: 'Phantasie ist wichtiger als Wissen, denn Wissen ist begrenzt.',
    author: 'Martin Limbeck',
  },
  {
    quoteText: 'Sie dürfen niemals Angst vor dem haben, was Sie tun, wenn es richtig ist.',
    author: 'Albert Einstein',
  },
  {
    quoteText: 'Die Liebe ist ein Erleben des anderen in der eigenen Seele.',
    author: 'Rudolf Steiner',
  },
  {
    quoteText: 'Es gibt eine unbezahlbare "Währung" gegen die "Einsamkeit". Freunde.',
    author: 'Klaus Seibold',
  },
  {
    quoteText: 'Wenn du es träumen kannst, kannst du es auch tun.',
    author: 'Walt Disney',
  },
  {
    quoteText: 'Eine Veränderung bewirkt steht eine weitere Veränderung.',
    author: 'Niccole Machiavelli',
  },
];

const SPECIALMOTTOS: Array<Motto> = [
  {
    date: '07-09',
    quoteText: 'Schön, dass du heute hier bist!',
    author: 'Dein PiA-Team',
  },
  {
    date: '07-23',
    quoteText: 'Nimm dir 3 Minuten um kurz inne zu halten und durchzuatmen.',
    author: 'Dein PiA-Team',
  },
  {
    date: '07-28',
    quoteText: 'Heute ist internationaler Tag der Freundschaft!',
    author: 'Dein PiA-Team',
  },
  {
    date: '07-31',
    quoteText: 'Heute ist Harry Potters Geburtstag! Wir wünschen alles Gute!',
    author: 'Dein PiA-Team',
  },
  {
    date: '08-06',
    quoteText: 'Heute ist internationaler Tag des Bieres! Prost!',
    author: 'Dein PiA-Team',
  },
  {
    date: '08-19',
    quoteText: 'Heute ist Welt-Foto-Tag? Heute schon ein Selfie gemacht?',
    author: 'Dein PiA-Team',
  },
  {
    date: '09-01',
    quoteText: 'Heute ist Tag des Zebrastreifens! Fahr vorsichtig!',
    author: 'Dein PiA-Team',
  },
  {
    date: '09-04',
    quoteText: 'Heute ist Welttag des Bartes! Mach den Kollegen doch mal ein Kompliment :)',
    author: 'Dein PiA-Team',
  },
  {
    date: '09-09',
    quoteText: 'Heute ist Tag des Wiener Schnitzels! Guten Appetit!',
    author: 'Dein PiA-Team',
  },
  {
    date: '19-09',
    quoteText: 'Arr, Heute ist "Sprich wie ein Pirat" Tag. Ahoi zusammen!',
    author: 'Dein PiA-Team',
  },
  {
    date: '09-10',
    quoteText: 'Heute ist der Tausche-Ideen-aus-Tag. Du hast eine Idee zu PiA? Lass es uns wissen!',
    author: 'Dein PiA-Team',
  },
  {
    date: '09-13',
    quoteText: 'Heute ist internationaler Tag der Schokolade - gönn dir mal was Süßes :)',
    author: 'Dein PiA-Team',
  },
  {
    date: '09-23',
    quoteText: 'Schön, dass du da bist!',
    author: 'Dein PiA-Team',
  },
  {
    date: '09-26',
    quoteText: 'Grüße doch heute einen Kollegen im PiA-Chat!',
    author: 'Dein PiA-Team',
  },
  {
    date: '10-07',
    quoteText: 'Heute ist Tag des Morgenmuffels - wir schlafen heute mal aus.',
    author: 'Dein PiA-Team',
  },
  {
    date: '10-12',
    quoteText: 'Heute ist internationaler Tag der Frustrationsschreie - AAH!',
    author: 'Dein PiA-Team',
  },
  {
    date: '10-24',
    quoteText: 'Wie happy bist du mit PiA? Lass es uns wissen!',
    author: 'Dein PiA-Team',
  },
  {
    date: '10-31',
    quoteText: 'Happy Halloween!',
    author: 'Dein PiA-Team',
  },
  {
    date: '11-03',
    quoteText: 'Liebe Männer, herzlichen Glückwunsch zum Weltmännertag!',
    author: 'Dein PiA-Team',
  },
  {
    date: '12-04',
    quoteText: 'Na? Schon die ersten Plätzchen gebacken?',
    author: 'Dein PiA-Team',
  },
  {
    date: '12-16',
    quoteText: 'Na? Schon den Weihnachtsbaum aufgestellt?',
    author: 'Dein PiA-Team',
  },
  {
    date: '12-22',
    quoteText: 'Weihnachten steht vor der Tür :-) Stimme dich heute schon mal mit Plätzchen und einer Tasse Tee ein.',
    author: 'Dein PiA-Team',
  },
  {
    date: '12-24',
    quoteText: 'Das PiA-Team wünscht ein frohes und besinnliches Weihnachtsfest!',
    author: 'Dein PiA-Team',
  },
  {
    date: '12-25',
    quoteText: 'Liebe Grüße zum ersten Weihnachtstag!',
    author: 'Dein PiA-Team',
  },
  {
    date: '12-26',
    quoteText: 'Einen schönen zweiten Feiertag!',
    author: 'Dein PiA-Team',
  },
  {
    date: '12-30',
    quoteText: 'Ob Silvester oder nicht, du bist der Knaller, auch im neuen Jahr! Einen guten Rutsch ins neue Jahr!',
    author: 'Dein PiA-Team',
  },
];
