import { Inject, Injectable } from '@angular/core';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';

import makeDebug from '../../../../makeDebug';
import { DatabaseFactory } from '../../services/database-factory';
import { DatabaseService } from '../../services/database.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement } from '../contracts/sync/sync-element';
import { DatabaseCreator as Creator } from '../contracts/sync/sync-endpoints';

const debug = makeDebug('sync:database-cleaner');

@Injectable()
export class DatabaseCreator implements ISyncElement {
  public get name(): string {
    return Creator;
  }

  constructor(
    private _databaseFactory: DatabaseFactory,
    private _databaseService: DatabaseService,
    @Inject(PlatformSyncToken) private _platformSync: IPlatformSync
  ) {}

  public async execute(_context: ISyncContext): Promise<void> {
    debug('calling createDatabase of database service');

    await this._platformSync.ready;

    const storages = await this._databaseFactory.createStorages(this._platformSync.isCapacitor);

    this._databaseService.setStorages(storages);

    if (!this._platformSync.canBeSynced) {
      return this._databaseService.clearAll();
    }
  }
}
