import { Injectable } from '@angular/core';
import { File } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';
import { extension } from 'mime-types';
import { Deferred } from 'src/app/common/deferred/deferred';
import { ExternalStorageService } from 'src/app/common/storage/external-storage.service';

import { IGenericStorage } from '../contracts/database/generic-storage';
import { DatabaseService } from '../database.service';
import { Database } from './Database';

@Injectable({ providedIn: 'root' })
export class BlobFileService {
  private _db: IGenericStorage;
  private _chunksMetaDb: IGenericStorage;
  private _database: Database;
  private _fileLocation: string;

  private _ready = new Deferred<void>();

  constructor(
    private _externalStorageService: ExternalStorageService,
    private _platform: Platform,
    private _file: File
  ) {}

  public create(databaseName: string, database: Database, databaseService: DatabaseService) {
    return new BlobFileService(this._externalStorageService, this._platform, this._file).init(
      databaseName,
      database,
      databaseService
    );
  }

  public async init(
    databaseName: string,
    database: Database,
    databaseService: DatabaseService
  ): Promise<BlobFileService> {
    this._db = await databaseService.getDatabase(databaseName);
    await this._platform.ready();

    if (database === Database.Blob && this._platform.is('hybrid')) {
      const externalDataStorage = this._file.dataDirectory;

      const attachmentsDirectory = 'attachments';
      try {
        await this._file.checkDir(externalDataStorage, attachmentsDirectory);
      } catch (error) {
        await this._file.createDir(externalDataStorage, attachmentsDirectory, true);
      }

      this._fileLocation = `${externalDataStorage}${attachmentsDirectory}`;

      this._chunksMetaDb = await databaseService.getDatabase('attachmentChunksMeta');
    }
    this._database = database;

    this._ready.resolve();

    return this;
  }

  public async get(id: string) {
    if (!id) {
      throw new Error('No valid id provided.');
    }

    await this._ready.promise;
    await this._platform.ready();

    if (!this._platform.is('hybrid')) {
      return this._db.get(id);
    }

    const validId = this.extractLocalPrefix(id);

    switch (this._database) {
      case Database.AttachmentToSave: {
        const data = await this._db.get(validId);
        if (!data) {
          return null;
        }

        data.data.blob = await new Promise<Blob>(async resolve => {
          const arrayBuffer = await this._file.readAsArrayBuffer(
            this._fileLocation,
            `${validId}.${extension(data.data.mime)}`
          );
          resolve(new Blob([arrayBuffer], { type: data.data.mime }));
        });

        return data;
      }
      case Database.Blob: {
        const chunksMeta = await this._chunksMetaDb.get(validId);
        if (!chunksMeta) {
          return null;
        }

        return new Promise<Blob>(async resolve => {
          const arrayBuffer = await this._file.readAsArrayBuffer(
            this._fileLocation,
            `${validId}.${extension(chunksMeta.mime)}`
          );
          resolve(new Blob([arrayBuffer], { type: chunksMeta.mime }));
        });
      }
    }
  }

  public async set(id: string, data: any) {
    if (!id || !data) {
      throw new Error('No valid attachment data provided.');
    }

    await this._ready.promise;
    await this._platform.ready();

    if (!this._platform.is('hybrid')) {
      return this._db.set(id, data);
    }

    const validId = this.extractLocalPrefix(id);

    switch (this._database) {
      case Database.Blob: {
        const mime = (data as Blob).type;
        await this._file.writeFile(this._fileLocation, `${validId}.${extension(mime)}`, data, { replace: true });
        await this._chunksMetaDb.set(validId, { mime });

        return data;
      }
      case Database.AttachmentToSave: {
        await this._file.writeFile(this._fileLocation, `${validId}.${extension(data.data.mime)}`, data.data.blob);
        delete data.data.blob;
        return this._db.set(validId, data);
      }
    }
  }

  public async remove(id: string): Promise<any> {
    if (!id) {
      throw new Error('No valid id provided.');
    }

    await this._ready.promise;
    await this._platform.ready();

    if (!this._platform.is('hybrid')) {
      return this._db.remove(id);
    }

    const validId = this.extractLocalPrefix(id);

    switch (this._database) {
      case Database.AttachmentToSave: {
        const data = await this._db.get(validId);
        if (!data) {
          return;
        }

        await this._file.removeFile(this._fileLocation, `${validId}.${extension(data.data.mime)}`);
        await this._db.remove(validId);
        return;
      }
      case Database.Blob: {
        const chunksMeta = await this._chunksMetaDb.get(validId);
        if (!chunksMeta) {
          return;
        }

        await this._file.removeFile(this._fileLocation, `${validId}.${extension(chunksMeta.mime)}`);
        await this._chunksMetaDb.remove(validId);
      }
    }
  }

  public async keys(): Promise<string[]> {
    await this._ready.promise;
    await this._platform.ready();

    if (!this._platform.is('hybrid')) {
      return this._db.keys();
    }

    switch (this._database) {
      case Database.Blob:
        return this._chunksMetaDb.keys();
      case Database.AttachmentToSave:
        return this._db.keys();
    }
  }

  // eslint-disable-next-line require-await
  public async createDB(): Promise<boolean> {
    return Boolean(this._db.create());
  }

  private extractLocalPrefix(id: string) {
    let validId = id;
    if (validId.startsWith('local|')) {
      validId = validId.substring(validId.indexOf('|') + 1, validId.length);
    }
    return validId;
  }
}
