import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { Platform } from '@ionic/angular';
import { ObservableModel } from 'src/app/common/viewmodel/observable-model';

@Injectable({ providedIn: 'root' })
export class NetworkInfoService {
  private _slowConnectionModel = new ObservableModel<boolean>(false, false);

  get isConnectionSlow(): boolean {
    return this._slowConnectionModel.get();
  }

  constructor(private readonly _platform: Platform) {}

  public async init() {
    await this._platform.ready();

    if (!this._platform.is('capacitor')) {
      return;
    }

    Network.addListener('networkStatusChange', status => {
      console.log('Network status:', status);

      if (status.connected) {
        this._slowConnectionModel.set(status.connectionType === 'cellular');
      }
    });
  }
}
