import { Inject, Injectable } from '@angular/core';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';

import makeDebug from '../../../../makeDebug';
import { WebDataSyncService } from '../../services/web-data-sync.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement } from '../contracts/sync/sync-element';
import { WebSyncStarter as Starter } from '../contracts/sync/sync-endpoints';

const debug = makeDebug('sync:web-sync-starter');

@Injectable()
export class WebSyncStarter implements ISyncElement {
  public get name(): string {
    return Starter;
  }
  private _isRunning = false;

  constructor(
    private _webDataSyncService: WebDataSyncService,
    @Inject(PlatformSyncToken) private _platformSync: IPlatformSync
  ) {}

  public async execute(context: ISyncContext): Promise<void> {
    debug('entered execute function', context);
    await this._platformSync.ready;

    if (
      this._platformSync.isCapacitor ||
      !context.params.isOnline ||
      context.params.token.cancelled.get() ||
      this._isRunning
    ) {
      return;
    }

    this._isRunning = true;

    debug('calling startSync of web-data-sync-service');

    await this._webDataSyncService
      .startSync(context.params.token)
      .catch(error => window.logger.error('Web data client sync failed', error));

    this._isRunning = false;
  }
}
