import { NgZone } from '@angular/core';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';
import { Requestor, StorageBackend } from '@openid/appauth';
import { AuthService, Browser, ConsoleLogObserver } from 'ionic-appauth';
import { environment } from 'src/environments/environment';
import makeDebug from 'src/makeDebug';

const debug = makeDebug('auth:authFactory');

export class AuthServiceWrapper extends AuthService {
  async removeToken(): Promise<void> {
    await this.storage.removeItem('token_response');
  }
}

export const authFactory = (
  platform: Platform,
  ngZone: NgZone,
  requestor: Requestor,
  browser: Browser,
  storage: StorageBackend
) => {
  const authService = new AuthServiceWrapper(browser, storage, requestor);
  authService.authConfig = environment.authCodeFlowConfigApp;

  if (!platform.is('capacitor')) {
    authService.authConfig.redirect_url = window.location.origin + '/auth/callback';
    authService.authConfig.end_session_redirect_url = window.location.origin + '/auth/logout';
  }

  if (platform.is('capacitor')) {
    authService.authConfig.redirect_url = 'appauth://callback';
    authService.authConfig.end_session_redirect_url = 'appauth://endsession';

    App.addListener('appUrlOpen', data => {
      debug('App.addListener::data ', data);
      if (!data.url) {
        return;
      }
      ngZone.run(() => {
        debug('authService.authConfig.redirect_url:', authService.authConfig.redirect_url);
        debug('authService.authConfig.end_session_redirect_url:', authService.authConfig.end_session_redirect_url);
        if (data.url.indexOf(authService.authConfig.redirect_url) === 0) {
          authService.authorizationCallback(data.url);
        } else if (data.url.indexOf(authService.authConfig.end_session_redirect_url) === 0) {
          authService.endSessionCallback();
        }
      });
    });
  }

  authService.addActionObserver(new ConsoleLogObserver());
  authService.init();
  return authService;
};
