import { Inject, Injectable, NgZone } from '@angular/core';
import { sortBy } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { v4 } from 'uuid';

import makeDebug from '../../makeDebug';
import { ICancellationToken } from '../common/contracts/cancellation/cancellation-token';
import { IPlatformSync, PlatformSyncToken } from '../common/contracts/sync/platform-sync';
import { Deferred } from '../common/deferred/deferred';
import { ObservableModel } from '../common/viewmodel/observable-model';
import { ConnectionStateService } from '../shared/services/connection-state.service';
import { IGenericStorage } from '../shared/services/contracts/database/generic-storage';
import { IConnectionStateService } from '../shared/services/contracts/sync/connection-state-service';
import { IFeathersAppProvider } from '../shared/services/contracts/sync/feathers-app-provider';
import { DatabaseService } from '../shared/services/database.service';
import { FeathersService } from '../shared/services/feathers.service';
import { CommandSender } from './command-sender';
import { ICommand } from './contracts/command';
import { ICommandQueue } from './contracts/command-queue';
import { ICommandQueueCount } from './contracts/command-queue-count';
import { CommandResult } from './command-result';
import { CancellationTokenHandler } from '../common/cancellation/cancellationtoken-handler';

const debug = makeDebug('command:command-queue');

@Injectable({ providedIn: 'root' })
export class CommandQueue implements ICommandQueue, ICommandQueueCount {
  private _deletingCommands = false;
  private _isRunning = false;
  private commandQueue: IGenericStorage;
  private _ready = new Deferred<void>();
  public queue$: Observable<boolean>;
  private _queueModel: ObservableModel<boolean>;
  private _count$ = new BehaviorSubject<number>(0);
  public currentError$ = new BehaviorSubject<Error>(undefined);

  get ready(): Promise<void> {
    return this._ready.promise;
  }

  public get count(): Observable<number> {
    return this._count$.asObservable();
  }

  constructor(
    private _commandSender: CommandSender,
    private _databaseService: DatabaseService,
    private _ngZone: NgZone,
    private _cancellationTokenHandler: CancellationTokenHandler,
    @Inject(ConnectionStateService) private _connectionStateService: IConnectionStateService,
    @Inject(FeathersService) private _feathersAppProvider: IFeathersAppProvider,
    @Inject(PlatformSyncToken) private _platformSync: IPlatformSync
  ) {
    this.init();
  }

  public async save(command: ICommand): Promise<void> {
    await this._ready.promise;
    await this.commandQueue.create();

    command._id = v4();
    command.timestamp = new Date();

    await this.commandQueue.set(command._id, command);
    this._count$.next(this._count$.value + 1);

    if (!this._platformSync.canBeSynced) {
      await this.syncInternal({
        cancelled: new ObservableModel(false, false),
        promise: undefined,
        cancel: () => undefined,
        reset: () => undefined,
      });
    }
  }

  public async sync(cancellationToken: ICancellationToken): Promise<void> {
    if (this._isRunning) {
      return;
    }

    this._isRunning = true;

    await this.ready;

    await this.syncInternal(cancellationToken);

    if (this._platformSync.canBeSynced) {
      cancellationToken.promise = this.createTimeout(cancellationToken);
    } else {
      cancellationToken.promise = Promise.resolve();
      this._isRunning = false;
    }
  }

  public async deleteDuplicatesFromQueue() {
    const currentError = this.currentError$.getValue();
    const params = currentError.message.match(
      /^Patient Duplikatprüfung fehlgeschlagen: id: ([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}), Vorname: [A-Za-z]+, Nachname: [A-Za-z]+ und Geburtsdatum: [0-9]{4}-[0-9]{2}-[0-9]{2}$/i
    );
    if (params.length !== 2) {
      throw Error('Could not parse error message');
    }
    const duplicatePatientId = params[1];
    const commands = await this.loadSortedCommands();
    const commandsToRemove = [];
    for (const command of commands) {
      // Find every command containing the duplicate patientId
      // Casting entity to any because we don't know which Entity it might be
      const entity: any = command.entity;
      if (
        entity?._id === duplicatePatientId ||
        entity?.patientId === duplicatePatientId ||
        entity?.metadata.patientId === duplicatePatientId
      ) {
        commandsToRemove.push(command);
      }
    }
    // Removing the found commands
    for (const command of commandsToRemove) {
      await this.removeCommandFromQueue(command._id);
    }
    // Delete error message
    this.currentError$.next(null);
    // Restart the queue
    const token = this._cancellationTokenHandler.getToken('commandQueue');
    await this.sync(token);
  }

  private async init() {
    this.subscribeToRemoteCommandEvents();

    this.commandQueue = await this._databaseService.getDatabase('commandQueue.db');

    const queueCount = await this.commandQueue.length();
    this._count$.next(queueCount);
    this._queueModel = new ObservableModel<boolean>(queueCount !== 0, false);
    if (!this._queueModel.get()) {
      this._queueModel.set(true);
    }
    this.queue$ = this._queueModel.data$;

    this._ready.resolve();
  }

  private async loadSortedCommands(): Promise<ICommand[]> {
    await this.commandQueue.create();

    let commands: ICommand[] = [];
    await this.commandQueue.forEach(command => {
      commands.push(command);
    });
    commands = sortBy(commands, ['timestamp']);

    debug(`finished loading of commands from db`, commands.length);
    return commands;
  }

  private async syncInternal(cancellationToken: ICancellationToken): Promise<any> {
    const commands = await this.loadSortedCommands();

    while (commands.length && !this._deletingCommands) {
      const command = commands.shift();
      if (cancellationToken.cancelled.get()) {
        debug('break sending commands of queue - cancelled by token');
        break;
      }

      const result: CommandResult = await this._commandSender.send(command);
      // Wenn ein Fehler aufgetreten ist
      if (result.error) {
        debug('break sending commands of queue - cancelled by error');
        this.currentError$.next(result.error);
        break;
      }
      // Wenn nicht erfolgreich aber kein Fehler auftritt (Zum Beispiel Offline)
      if (!result.successful) {
        debug('break sending commands of queue - cancelled by unsuccessful command');
        break;
      }
      this.currentError$.next(null);
      await this.removeCommandFromQueue(command._id);
    }

    this._queueModel.set((await this.commandQueue.length()) !== 0);
  }

  private async removeCommandFromQueue(commandId: string) {
    await this.commandQueue.remove(commandId);
    const remainingCommandsInQueueCount = await this.commandQueue.length();
    debug(`${remainingCommandsInQueueCount} open commands`);
    this._count$.next(remainingCommandsInQueueCount);
  }

  private subscribeToRemoteCommandEvents() {
    this._connectionStateService.connected.pipe(filter(isOnline => isOnline)).subscribe(async () => {
      await this._feathersAppProvider.ready;

      const service = this._feathersAppProvider.app.service('resqueue');

      service.removeAllListeners('requestCommands');

      service.on('requestCommands', _ => this.responseCommands(service, _.connection_id));
    });
  }

  private async responseCommands(service: any, connection_id: any) {
    const commands = await this.getAllCommands();

    service.update(null, {
      commands,
      event: 'responseCommands',
      connection_id,
    });
  }

  public async getAllCommands() {
    await this.commandQueue.create();

    const keys = await this.getKeys();

    const commands = [];

    for (const key of keys) {
      try {
        const command = await this.commandQueue.get(key);
        commands.push(command);
      } catch (error) {
        window.logger.error(`failed to load command from queue`, error);
      }
    }

    return sortBy(commands, ['timestamp']);
  }

  public async deleteFirstCommand() {
    this._deletingCommands = true;

    await this.commandQueue.create();

    const keys = await this.getKeys();

    let commands = [];

    for (let index = 0; index <= keys.length - 1; index++) {
      try {
        const command = await this.commandQueue.get(keys[index]);
        commands.push(command);
      } catch (error) {
        window.logger.error('failed to read command from queue.', error);
        await this.commandQueue.remove(keys[index]);
        return;
      }
    }

    commands = sortBy(commands, ['timestamp']);

    if (!commands.length) {
      this._deletingCommands = false;
      return;
    }

    await this.commandQueue.remove(commands[0]._id);
    this._count$.next(this._count$.value - 1);

    this._queueModel.set((await this.commandQueue.length()) !== 0);

    this._deletingCommands = false;

    if (window && window.logger) {
      window.logger.error(`First command removed: ${JSON.stringify(commands[0])}`, null);
    }
  }

  public async deleteAllCommands() {
    this._deletingCommands = true;

    await this.commandQueue.create();

    const keys = await this.getKeys();

    for (const key of keys) {
      await this.commandQueue.remove(key);
      this._count$.next(this._count$.value - 1);
    }
    this._queueModel.set(false);

    this._deletingCommands = false;
  }

  private async createTimeout(cancellationToken: ICancellationToken): Promise<void> {
    debug('starting queue timeout');
    // eslint-disable-next-line no-constant-condition
    while (true) {
      await this.sleep(2000);

      await this.syncInternal(cancellationToken);

      if (cancellationToken.cancelled.get()) {
        debug('breaking queue timeout');
        this._isRunning = false;
        break;
      }
    }
  }

  private sleep(ms?: number) {
    return new Promise<void>(resolve =>
      this._ngZone.runOutsideAngular(() => {
        const timeout = global.setTimeout(() => {
          global.clearTimeout(timeout);
          resolve();
        }, ms || 0);
      })
    );
  }

  private async getKeys() {
    return (this.commandQueue as any).getAllKeys
      ? await new Promise<string[]>((resolve, reject) =>
          (this.commandQueue as any).getAllKeys((error, keys: string[]) => (error ? reject(error) : resolve(keys)))
        )
      : await this.commandQueue.keys();
  }
}
