import { Inject, Injectable, NgZone } from '@angular/core';
import { AttachmentType, IPatient } from '@pia/pia.shared';
import { takeWhile } from 'rxjs/operators';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { ISyncState } from 'src/app/common/contracts/sync/sync-state';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';
import { PatientDB } from 'src/app/common/repository/databases';
import { AttachmentFeathersCallbacks } from 'src/app/shared/services/attachment/attachment-feathers-callback';
import { AttachmentDto } from '../../models/attachment/attachment-dto.model';
import { AttachmentModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { AttachmentService } from '../attachment/attachment.service';
import { AuthService } from '../auth.service';
import { IFeathersAppProvider } from '../contracts/sync/feathers-app-provider';
import { EventService } from '../event.service';
import { QueryService } from '../query/query.service';
import { SyncService } from './sync.service';

@Injectable()
export class AttachmentSyncService extends SyncService<AttachmentDto> {
  private _myPatients: IPatient[] = [];

  constructor(
    private _authenticationService: AuthService,
    private _queryService: QueryService,
    private _attachmentService: AttachmentService,
    private _attachmentFeathersCallbacks: AttachmentFeathersCallbacks,
    appController: AppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<AttachmentDto>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(AttachmentModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  public canSync(): boolean {
    return false;
  }

  public async setup(feathersAppProvider: IFeathersAppProvider): Promise<void> {
    await super.setup(feathersAppProvider);

    this._service.removeAllListeners('created');
    this._service.removeAllListeners('updated');
    this._service.removeAllListeners('patched');
    this._service.removeAllListeners('removed');

    this._service.on('created', async attachment => {
      await this._attachmentFeathersCallbacks.onCreated(attachment);

      if (this.platformSync.isCapacitor) {
        await this.downloadMySwodocImages(attachment);
      }

      await this.fetchMyPatients();

      if (await this.isMyAttachment(attachment)) {
        await this._dispatcher.createState(AttachmentModelName, attachment);
      }
    });

    this._service.on('removed', item => this._attachmentFeathersCallbacks.onRemoved(item));
  }

  protected async afterSync(syncState: ISyncState<AttachmentDto>) {
    try {
      if (syncState.created && syncState.created.length) {
        await this.fetchMyPatients();

        for (const attachment of syncState.created) {
          if (await this.isMyAttachment(attachment)) {
            await this._dispatcher.createState(AttachmentModelName, attachment);
          }
        }

        if (this.platformSync.isCapacitor) {
          await Promise.all(
            syncState.created.map(async attachment => {
              if (attachment.archived) {
                return;
              }

              await this.downloadMySwodocImages(attachment);
            })
          );
        }
      }

      if (syncState.deleted && syncState.deleted.length) {
        for (const attachment of syncState.deleted) {
          await this._attachmentFeathersCallbacks.onArchived(attachment._id);
        }
      }
    } catch (error) {
      window.logger.error('SYNC AFTERSYNC ATTACHMENTS', error);
    }
  }

  private async downloadMySwodocImages(attachment: AttachmentDto) {
    switch (attachment.metadata.type) {
      case AttachmentType.SwodocImage:
      case AttachmentType.SwodocPicture:
      case AttachmentType.SwodocSignature:
        if (await this.isMyAttachment(attachment, true)) {
          await this._attachmentService.download(attachment._id, attachment.contentType);
        }
        break;
      default:
        return;
    }
  }

  // eslint-disable-next-line require-await
  private async isMyAttachment(attachment: AttachmentDto, isSwodoc = false): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this._authenticationService.authenticatedEventPublisher
        .pipe(takeWhile(authEventData => !authEventData.isAuthenticated))
        .subscribe({
          complete: () => {
            const patient = this._myPatients
              .filter(myPatient => !myPatient.deactivationReason && myPatient.accountingStatus)
              .find(
                myPatient =>
                  attachment.metadata.patientId &&
                  myPatient._id === attachment.metadata.patientId &&
                  (isSwodoc ? attachment.metadata.auditId != null : attachment.metadata.auditId == null)
              );

            if (patient && !isSwodoc) {
              (attachment as any).patient = patient;
            }

            resolve(patient != null);
          },
        });
    });
  }

  private async fetchMyPatients() {
    this._myPatients = await this._queryService.search<IPatient>(
      {
        query: `fieldNurseId:${this._authenticationService.authentication.account._id} additionalUserIds:${this._authenticationService.authentication.account._id}`,
      },
      PatientDB,
      { isIn: true }
    );
  }
}
