import { SQLiteObject } from '@ionic-native/sqlite/ngx';
import { chunk, get, isArray, xor } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { IGenericStorage } from 'src/app/shared/services/contracts/database/generic-storage';
import { IIndexMetaDataInfo } from 'src/app/shared/services/contracts/database/index-metadata-info';
import { IIndexRepairProgress } from 'src/app/shared/services/contracts/database/index-repair-progress';

import { IndexField } from './index-field';

const chunkSizes = { default: 500, audit: 25, order: 75, erpOrder: 75, device: 75, careProposal: 250 };

export class SqliteStorage implements IGenericStorage {
  constructor(
    private _dbPromise: Promise<SQLiteObject>,
    private _table: string,
    private _fields: IndexField[],
    private _serialize = item => JSON.stringify(item),
    private _deserialize = item => JSON.parse(item),
    public isDeletable: boolean,
    public canRepairIndex = true
  ) {}

  get(key: string): Promise<any> {
    return this.getItem(key);
  }

  remove(key: string): Promise<any> {
    return this.removeItem(key);
  }

  set(key: string, value: any): Promise<any> {
    return this.setItem(key, value);
  }

  async clear(): Promise<void> {
    const promises = [];
    promises.push(
      this._dbPromise.then(db => db.transaction(tx => tx.executeSql(`DELETE FROM [${this._table}]; VACUUM;`, [])))
    );
    promises.push(this._dbPromise.then(db => db.transaction(tx => tx.executeSql(`DELETE FROM [${this._table}_fts]`))));

    await Promise.all(promises);
  }

  forEach(iteratorCallback: (value: any, key: string, iterationNumber: number) => any): Promise<void> {
    let index = 0;

    return new Promise(resolve => {
      this.getAll().subscribe(
        item => {
          iteratorCallback(item, item._id, index++);
        },
        error => {
          window.logger.error('Error occured during db iteration', error);
          resolve();
        },
        () => resolve()
      );
    });
  }

  async keys(): Promise<string[]> {
    const results = [];

    try {
      const resultSet = await this._dbPromise.then(db => db.executeSql(`SELECT KEY FROM [${this._table}]`, []));

      for (let index = 0; index < resultSet.rows.length; index++) {
        results.push(String(resultSet.rows.item(index).key));
      }
    } catch (error) {
      window.logger.error('SQLite SELECT', error);
      throw new Error(`SELECT error: ${error.message}`);
    }

    return results;
  }

  create(): Promise<any> {
    return this._dbPromise;
  }

  removeItem(key: string): Promise<any> {
    return this._dbPromise.then(db => db.executeSql(`DELETE FROM [${this._table}] WHERE KEY = ?;`, [key]));
  }

  removeItems(key: string): Promise<any> {
    return this._dbPromise.then(db => db.executeSql(`DELETE FROM [${this._table}] WHERE KEY LIKE ${key}%`));
  }

  async length(byIndex = false): Promise<number> {
    let count = 0;

    try {
      const resultSet = await this._dbPromise.then(db =>
        db.executeSql(`SELECT COUNT(key) as c FROM [${this._table}]${byIndex ? '_fts' : ''}`, [])
      );
      count = resultSet.rows.item(0).c;
    } catch (error) {
      window.logger.error('SQLite SELECT', error);
      throw new Error(`SELECT error: ${error.message}`);
    }

    return count;
  }

  async getItem(key: string): Promise<any> {
    let result;

    try {
      const resultSet = await this._dbPromise.then(db =>
        db.executeSql(`SELECT * FROM [${this._table}] WHERE KEY = ? LIMIT 1`, [key])
      );
      if (resultSet.rows.length) {
        result = this._deserialize(resultSet.rows.item(0).value);
      }
    } catch (error) {
      window.logger.error('SQLite SELECT', error);
      throw new Error(`SELECT error: ${error.message}`);
    }
    return result;
  }

  async getItems(keys: string[]): Promise<any[]> {
    const results = [];

    if (!keys || !keys.length) {
      return results;
    }

    try {
      const generator = this.buildChunkedStatement(keys);
      let keysTemp = generator.next();
      while (!keysTemp.done && isArray(keysTemp.value)) {
        const orderBy = keysTemp.value.map(key => `KEY="${key}" DESC`).join(',');
        const statement = `SELECT * FROM [${this._table}] WHERE KEY IN (${keysTemp.value
          .map(key => `"${key}"`)
          .join(',')}) ORDER BY ${orderBy}`;
        const resultSet = await this._dbPromise.then(db => db.executeSql(statement, []));

        for (let index = 0; index < resultSet.rows.length; index++) {
          results.push(this._deserialize(resultSet.rows.item(index).value));
        }
        keysTemp = generator.next();
      }
    } catch (error) {
      window.logger.error('SQLite SELECT', error);
      throw new Error(`SELECT error: ${error.message}`);
    }
    return results;
  }

  *buildChunkedStatement(keys: string[]) {
    while (keys.length) {
      yield keys.splice(0, 500);
    }
  }

  getAll(chunkSize: number = 250): Observable<any> {
    const subject = new Subject();

    (async () => {
      try {
        const length = await this.length();
        const getAllWorkloadBound = getAllWorkload.bind(this);
        window.requestIdleCallback(getAllWorkloadBound({ subject, chunkSize, offset: 0, length }), { timeout: 500 });
      } catch (error) {
        window.logger.error('SQLite SELECT', error);
        throw new Error(`SELECT error: ${error.message}`);
      }
    })();

    return subject.asObservable();
  }

  executeBatch(batch: any[]): Promise<any> {
    try {
      return this._dbPromise.then(db => db.sqlBatch(batch));
    } catch (error) {
      window.logger.error('SQLite Batch', error);
    }
  }

  repairIndex(progressSubject: Subject<IIndexRepairProgress>): void {
    if (!this._fields.length) {
      progressSubject.next({ processedItems: 1, totalItems: 1 });
      progressSubject.complete();
      return;
    }

    this.recreateIndexTable().then(() => this.refillIndex(progressSubject));
  }

  public async recreateIndex(): Promise<any> {
    if (!this._fields.length) {
      return;
    }

    try {
      const indexColumnNames = await this.getIndexColumnNames();
      const fieldNames = this._fields.map(field => field.name);
      const hasFieldDifferences = xor(indexColumnNames, fieldNames).length > 0;
      if (!hasFieldDifferences) {
        window.logger.log('no mismatch found.');
        return;
      }
      await this.recreateIndexTable();
      await this.refillIndex();
    } catch (error) {
      window.logger.error('Recreate index error', error);
    }
  }

  public readIndexFieldMetaInfo(item): IIndexMetaDataInfo {
    const result = { entries: [], fieldNames: [], bindings: '' };
    for (const field of this._fields) {
      result.fieldNames.push(field.name);
      const fieldValue = get(item, field.path);
      result.entries.push(
        typeof fieldValue === 'undefined' ? null : Array.isArray(fieldValue) ? fieldValue.join(', ') : fieldValue
      );
    }

    result.bindings = this._fields.map((key, index) => `?${index + 2}`).join(', ');

    return result;
  }

  private refillIndex(progressSubject?: Subject<IIndexRepairProgress>) {
    return new Promise<void>((resolve, reject) => {
      const items = [];
      this.getAll().subscribe({
        next: item => items.push(item),
        complete: async () => {
          try {
            let processedItems = 0;
            const chunkSize = chunkSizes[this._table] || chunkSizes['default'];
            window.logger.log(`inserting batch for ${this._table} of size ${chunkSize}.`);
            const chunkedItems = chunk(items, chunkSize);
            for (const chunkItem of chunkedItems) {
              const indexBatches = [];
              for (const item of chunkItem) {
                const indexValues = this.readIndexFieldMetaInfo(item);
                indexBatches.push([
                  `INSERT INTO [${this._table}_fts] (id, ${indexValues.fieldNames.join(', ')}) VALUES (?1, ${
                    indexValues.bindings
                  })`,
                  [String(item._id), ...indexValues.entries.map(entry => String(entry).toLocaleLowerCase())],
                ]);
              }
              if (indexBatches.length) {
                processedItems = processedItems + indexBatches.length;
                await this.executeBatch(indexBatches);
                if (progressSubject) {
                  progressSubject.next({ totalItems: items.length, processedItems });
                }
                window.logger.log(`batch of ${indexBatches.length} items executed successfully.`);
              }
            }

            progressSubject.complete();
            resolve();
          } catch (error) {
            progressSubject.error(error);
            reject(error);
          }
        },
      });
    });
  }

  private async recreateIndexTable() {
    await this._dbPromise.then(db => db.executeSql(`DROP TABLE IF EXISTS [${this._table}_fts];`, []));
    const fieldNames = this._fields.map(field => field.name).join(', ');
    const searchTableStatement = `CREATE VIRTUAL TABLE IF NOT EXISTS ${this._table}_fts USING fts4(id, ${fieldNames})`;
    await this._dbPromise.then(db => db.executeSql(searchTableStatement, []));
  }

  private getIndexColumnNames(): Promise<string[]> {
    return this._dbPromise.then(async db => {
      const resultSet = await db.executeSql(
        `SELECT name, sql FROM sqlite_master WHERE type="table" AND name = "${this._table}_fts";`,
        []
      );
      return (
        resultSet.rows
          .item(0)
          // eslint-disable-next-line no-useless-escape
          .sql.replace(/^[^\(]+\(([^\)]+)\)/g, '$1')
          .split(',')
          .map(value => value.trim())
          .filter(value => value !== 'id')
      );
    });
  }

  async search(query: string, params?: any[]): Promise<any> {
    const results = [];

    try {
      const resultSet = await this._dbPromise.then(db => db.executeSql(query, params));
      for (let index = 0; index < resultSet.rows.length; index++) {
        const dbValue = resultSet.rows.item(index).id;
        results.push(typeof dbValue === 'string' ? dbValue : this._deserialize(dbValue));
      }
    } catch (error) {
      window.logger.error('SQLite Search', error);
    }

    return results;
  }

  setItems(itemsForDb: { items: any[]; deletable: boolean }, batch: any[]): Promise<any> {
    return this.executeBatch(batch);
  }

  async setItem(key: string, value: any): Promise<any> {
    try {
      await this._dbPromise.then(db =>
        db.executeSql(`INSERT OR REPLACE INTO [${this._table}] (key, value) VALUES (?1, ?2)`, [
          key,
          this._serialize(value),
        ])
      );
    } catch (error) {
      window.logger.error('SQLite INSERT', error);
      throw new Error(`INSERT error: ${error.message}`);
    }
    return value;
  }
}

function getAllWorkload({ subject, chunkSize, offset, length }) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const _that = this;

  const getAllWorkloadBound = getAllWorkload.bind(_that);

  return async function (deadline) {
    while ((deadline.timeRemaining() > 0 || deadline.didTimeout) && length > 0) {
      const resultSet = await _that._dbPromise.then(db =>
        db.executeSql(`SELECT * FROM [${_that._table}] LIMIT (?1) OFFSET (?2)`, [chunkSize, offset])
      );

      for (let index = 0; index < resultSet.rows.length; index++) {
        subject.next(_that._deserialize(resultSet.rows.item(index).value));
      }

      length -= chunkSize;

      offset += chunkSize;
      if (length > 0) {
        window.requestIdleCallback(getAllWorkloadBound({ subject, chunkSize, offset, length }), { timeout: 500 });
        return;
      }
    }

    if (length > 0) {
      window.requestIdleCallback(getAllWorkloadBound({ subject, chunkSize, offset, length }), { timeout: 500 });
    } else {
      subject.complete();
    }
  };
}
