import {
  Gender,
  IAddress,
  ICareGiver,
  IDeliveryInformation,
  IPatient,
  IPatientDoctorRelation,
  IPatientHospitalRelation,
  IPatientNursingHomeRelation,
  IPatientNursingServiceRelation,
  IPatientPayerRelation,
  IPatientPharmacyRelation,
  NotificationType,
  PatientDeactivationReason,
  Title,
} from '@pia/pia.shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';
import { AggregateRoot } from '../aggregate-root';

export class PatientDto extends AggregateRoot implements IPatient, ISearchable {
  accountingStatus: boolean;
  additionalAddress2: string;
  additionalAddress: string;
  /**
   * Used to display all participating users
   */
  additionalUserIds: string[];
  address: string;
  backOfficeName: string;
  backOfficePhone: string;
  billingAddress: IAddress;
  birthday: Date;
  careDegree: 1 | 2 | 3 | 4 | 5;
  careGivers: ICareGiver[];
  changeInSupplier: boolean;
  city: string;
  classification: string;
  comment: string;
  createdAt: Date;
  createdBy: string;
  customerContactId: string;
  customerId: string;
  deactivationComment: string;
  deactivationDate: Date;
  deactivationReason: PatientDeactivationReason;
  deliveryAddress: IAddress;
  deliveryInformation: IDeliveryInformation;
  email: string;
  erpStatus: string;
  fax: string;
  fieldNurseId: string;
  firstName: string;
  gender: Gender;
  hospital: IPatientHospitalRelation;
  ignoreChangeForResmed: boolean;
  imported: boolean;
  ivTherapy: boolean;
  lastName: string;
  mobilePhone: string;
  nextVisit: Date;
  notificationType: NotificationType;
  nursingHome: IPatientNursingHomeRelation;
  nursingHomeId: string;
  nursingService: IPatientNursingServiceRelation;
  nursingServiceId: string;
  payer: IPatientPayerRelation;
  pharmacy: IPatientPharmacyRelation;
  pharmacyId: string;
  phone: string;
  postalCode: string;
  postalCodeId: string;
  primaryDoctor: IPatientDoctorRelation;
  primaryDoctorId: string;
  regionId: string;
  resmedTenant: string;
  title: Title;
  updatedAt: Date;
  updatedBy: string;
  validToDate: Date;

  constructor() {
    super();
    this.gender = Gender.Unknown;
    this.firstName = this.lastName = this.fieldNurseId = '';
    this.careGivers = [];
  }

  get displayText(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
