import { Inject, Injectable } from '@angular/core';
import { SQLite, SQLiteDatabaseConfig, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Platform } from '@ionic/angular';
import DB_PLUGINS, { PluginsToken } from '../repository/index-plugins';
import { IndexField } from './index-field';
import { SqliteStorage } from './sqlite-storage';

@Injectable({ providedIn: 'root' })
export class SqliteStorageFactory {
  private _dbPromise: Promise<SQLiteObject>;
  private _deserialize;
  private _serialize;

  constructor(
    private _sqlite: SQLite,
    @Inject(PluginsToken) private _plugins: typeof DB_PLUGINS,
    private _platform: Platform
  ) {}

  public async configure(
    databaseName: string,
    serialize = item => JSON.stringify(item),
    deserialize = item => JSON.parse(item)
  ): Promise<void> {
    this._serialize = serialize;
    this._deserialize = deserialize;

    await this._platform.ready();

    if (!this._platform.is('capacitor')) {
      return;
    }

    this._dbPromise = new Promise(async (resolve, reject) => {
      try {
        const db: SQLiteObject = await this._sqlite.create({
          name: databaseName,
          key: undefined,
          location: 'default',
        } as SQLiteDatabaseConfig);

        resolve(db);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async create(storageName: string, isDeletable = true, canRepairIndex = true): Promise<SqliteStorage> {
    const table = storageName.substring(0, storageName.length - 3);

    const sqlStatement = `CREATE TABLE IF NOT EXISTS [${table}] (id INTEGER PRIMARY KEY, key unique, value)`;
    await this._dbPromise.then(db => db.executeSql(sqlStatement, []));
    const indexStatement = `CREATE UNIQUE INDEX IF NOT EXISTS idx_${table}_key ON [${table}] (key);`;
    await this._dbPromise.then(db => db.executeSql(indexStatement, []));

    const plugin = this._plugins[storageName];
    const fields = plugin
      ? plugin.fields.map(field => (typeof field === 'string' ? new IndexField(field) : new IndexField(field.name)))
      : [];
    const fieldNames = fields.map(field => field.name).join(', ');
    if (fields.length > 0) {
      const searchTableStatement = `CREATE VIRTUAL TABLE IF NOT EXISTS ${table}_fts USING fts4(id, ${fieldNames})`;
      await this._dbPromise.then(db => db.executeSql(searchTableStatement, []));
    }
    return new SqliteStorage(
      this._dbPromise,
      table,
      fields,
      this._serialize,
      this._deserialize,
      isDeletable,
      canRepairIndex
    );
  }
}
