export enum CountryCode {
  AD = 'Andorra',
  AE = 'Vereinigte Arabische Emirate',
  AF = 'Afghanistan',
  AG = 'Algerien',
  AL = 'Albanien',
  AR = 'Argentinien',
  AT = 'Österreich',
  AU = 'Australien',
  AZ = 'Aserbaidschan',
  BB = 'Barbados',
  BD = 'Bangladesch',
  BE = 'Belgien',
  BF = 'Burkina Faso',
  BG = 'Bulgarien',
  BH = 'Bahrain',
  BI = 'Burundi',
  BJ = 'Benin',
  BN = 'Brunei Darussalam',
  BO = 'Bolivien',
  BOS = 'Bosnien-Herzegowina',
  BR = 'Brasilien',
  BS = 'Bahamas',
  BW = 'Botswana',
  BY = 'Weißrussland / Belarus',
  CA = 'Kanada',
  CD = 'Kongo',
  CF = 'Zentralafrikanische Republik',
  CH = 'Schweiz',
  CI = 'Elfenbeinküste',
  CL = 'Chile',
  CM = 'Kamerun',
  CN = 'China',
  CO = 'Kolumbien',
  CR = 'Costa Rica',
  CU = 'Kuba',
  CV = 'Kap Verde',
  CW = 'Curaçao',
  CY = 'Zypern',
  CZ = 'Tschechische Republik',
  DE = 'Deutschland',
  DK = 'Dänemark',
  DO = 'Dominikanische Republik',
  DZ = 'Algerien',
  EC = 'Ecuador',
  EE = 'Estland',
  EG = 'Ägypten',
  ES = 'Spanien',
  ET = 'Äthiopien',
  FI = 'Finnland',
  FJ = 'Fidschiinseln',
  FL = 'Fürstentum Liechtenste',
  FR = 'Frankreich',
  GB = 'Great Britain',
  GBM = 'Insel Man',
  GE = 'Georgien',
  GG = 'Guernsey',
  GH = 'Ghana',
  GI = 'Gibraltar',
  GL = 'Grönland',
  GM = 'Gambia',
  GN = 'Guinea',
  GR = 'Griechenland',
  GT = 'Guatemala',
  GW = 'Guinea-Bissau',
  HK = 'Hongkong',
  HN = 'Honduras',
  HR = 'Kroatien',
  HU = 'Ungarn',
  ID = 'Indonesien',
  IE = 'Irland',
  IL = 'Israel',
  IN = 'Indien',
  IQ = 'Irak',
  IR = 'Iran',
  IS = 'Island',
  IT = 'Italien',
  JE = 'Jersey',
  JM = 'Jamaika',
  JO = 'Jordanien',
  JP = 'Japan',
  KE = 'Kenia',
  KR = 'Korea',
  KW = 'Kuwait',
  KZ = 'Kasachstan',
  LB = 'Libanon',
  LI = 'Liechtenstein',
  LK = 'Sri Lanka',
  LS = 'Lesotho',
  LT = 'Litauen',
  LU = 'Luxemburg',
  LV = 'Lettland',
  LY = 'Libyen',
  MA = 'Marokko',
  MC = 'Monaco',
  ME = 'Montenegro',
  MK = 'Mazedonien',
  ML = 'Mali',
  MN = 'Mongolei',
  MOL = 'Moldawien',
  MR = 'Mauretanien',
  MT = 'Malta',
  MU = 'Mauritius',
  MV = 'Republic of Maldives',
  MW = 'Malawi',
  MX = 'Mexiko',
  MY = 'Malaysia',
  MZ = 'Mosambik',
  NA = 'Niederländische Antillen',
  NG = 'Nigeria',
  NI = 'Nicaragua',
  NL = 'Niederlande',
  NO = 'Norwegen',
  NP = 'Nepal',
  NZ = 'Neuseeland',
  OM = 'Oman',
  PA = 'Panama',
  PE = 'Peru',
  PG = 'Papua-Neuguinea',
  PH = 'Philippinen',
  PK = 'Pakistan',
  PL = 'Polen',
  PS = 'Staat Palästina',
  PT = 'Portugal',
  PY = 'Paraguay',
  QA = 'Katar',
  RO = 'Rumänien',
  RS = 'Serbien',
  RSM = 'San Marino',
  RU = 'Russland',
  RW = 'Ruanda',
  SA = 'Saudi-Arabien',
  SB = 'Salomonen',
  SC = 'Seychellen',
  SD = 'Sudan',
  SE = 'Schweden',
  SG = 'Singapur',
  SI = 'Slowenien',
  SK = 'Slowakische Republik',
  SL = 'Sierra Leone',
  SN = 'Senegal',
  SV = 'El Salvador',
  SY = 'Syrien',
  SZ = 'Eswatini',
  TD = 'Tschad',
  TH = 'Thailand',
  TM = 'Turkmenistan',
  TN = 'Tunesien',
  TR = 'Türkei',
  TT = 'Trinidad & Tobago',
  TZ = 'Tansania',
  UA = 'Ukraine',
  UG = 'Uganda',
  US = 'USA',
  UY = 'Uruguay',
  UZ = 'Usbekistan',
  VA = 'Vatikanstadt',
  VE = 'Venezuela',
  VN = 'Vietnam',
  VU = 'Vanuatu',
  WA = 'Namibia',
  WS = 'West-Samoa',
  XK = 'Kosovo',
  YE = 'Jemen',
  YU = 'Jugoslawien',
  ZA = 'Südafrika',
  ZM = 'Sambia',
  ZW = 'Simbabwe',
}

export const DEFAULT_COUNTRY_CODE = 'DE';
