import { ISearchable } from 'src/app/common/contracts/components/searchable';

export class CountryCodeDto implements ISearchable {
  public _id: string;
  public label = '';

  public get displayText(): string {
    return this.label;
  }
}
