import { Inject, Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { IGenericStorage } from 'src/app/shared/services/contracts/database/generic-storage';
import { IFeathersAppProvider } from 'src/app/shared/services/contracts/sync/feathers-app-provider';
import { DatabaseService } from 'src/app/shared/services/database.service';
import { FeathersService } from 'src/app/shared/services/feathers.service';

import { IRepository } from '../contracts/repository/repository';
import { IPlatformSync, PlatformSyncToken } from '../contracts/sync/platform-sync';
import { IUnitOfWork } from '../contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from '../contracts/unit-of-work/unit-of-work-factory';
import { Deferred } from '../deferred/deferred';
import { PluginsToken } from '../repository/index-plugins';
import { AlbertaStorage } from '../storage/alberta-storage';
import { UnitOfWork } from './unit-of-work';

@Injectable({ providedIn: 'root' })
export class UnitOfWorkFactory implements IUnitOfWorkFactory {
  private _ready: Deferred<void> = new Deferred();
  private _repositories: Record<string, IRepository<any>> = {};
  private _unitOfWork: IUnitOfWork;

  constructor(
    private _dbProvider: DatabaseService,
    @Inject(PluginsToken) private _plugins: {},
    @Inject(PlatformSyncToken) private _platformSync: IPlatformSync,
    private _authenticationService: AuthService,
    @Inject(FeathersService) private _feathersAppProvider: IFeathersAppProvider
  ) {
    this.init();
  }

  private async init() {
    await this._platformSync.ready;

    const storageFactory = this._platformSync.isCapacitor
      ? (storage: IGenericStorage) => storage
      : (storage: IGenericStorage) => Object.assign(new AlbertaStorage(undefined, undefined), storage);

    this._unitOfWork = new UnitOfWork(
      this._dbProvider,
      this._repositories,
      this._plugins,
      storageFactory,
      this._authenticationService,
      this._feathersAppProvider,
      this._platformSync
    );

    this._ready.resolve();
  }

  async create(): Promise<IUnitOfWork> {
    await this._ready.promise;

    return this._unitOfWork;
  }
}
